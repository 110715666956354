.swiper-button-prev,
.swiper-button-next{
    background-color: blue;
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
}

#loading-icon-container{
    background: white;
    background: linear-gradient(0deg, white 75%, rgba(255, 255, 255, 0) 100%);
    z-index: 99;
    bottom: 0px;
    position: fixed;
    width: 100%;
}

#loading-icon{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 100;
    width: 100%;
    :first-child{
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

