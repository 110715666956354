.hacker-card{
    overflow: hidden;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    max-width: 375px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    &.job{
        padding-bottom: 40px;
        & #comment-count{
            display: none;
        }
    }
}

.story-url{
    color: grey;
}

.story-url,
.points-info{
    font-size: 12px;
}

.story-title,
.story-url,
.points-info{
    text-align: center;
}

.points-info{
    margin-top: 5px;
}

.see-on-hn-overlay{
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 55%, rgba(255,255,255,0) 100%);
    height: 90px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    .see-on-hn{
        width: 100%;
        position: absolute;
        bottom: 20px;
        text-align: center;
        font-weight: bold;
    }
}

.top-comments{
    text-align: left;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    max-height: 700px;
    min-height: 60px;
    h3{
        margin-block-start: .5em;
        margin-block-end: .5em;
    }
    .no-comments{
        text-align: center;
        margin-top: 50px;
    }
    .comment{
        overflow: auto;
        padding: 15px 0px;
        border-bottom: 1px solid grey;
        &:last-of-type{
            border-bottom: none;
            margin-bottom: 50px;
        }
    }
    .comment-by{
        font-weight: bold;
        margin-bottom: 10px;
    }
}
